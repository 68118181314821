import { init, logEvent } from '@amplitude/analytics-browser'
import React, { JSX, Suspense, useContext, useEffect, useState } from 'react'
import { Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import { iMSCContext } from '@/common/iMSCContext'

import { isEmpty } from '@/utils/common'

import { ReturnDTO, setAuthorizationToken } from '@/apis/http'
import AuthService from '@/apis/AuthService'

import rootStyle from '@/scss/parenting-test/style.module.scss'

// Common
const Main = React.lazy(() => import('@/pages/Main'))
const Terms = React.lazy(() => import('@/pages/Terms'))
const Quit = React.lazy(() => import('@/pages/Quit'))

// Account
const Signin = React.lazy(() => import('@/pages/acc/Signin'))
const FindAccount = React.lazy(() => import('@/pages/acc/FindAccount'))
const Signup = React.lazy(() => import('@/pages/acc/Signup'))
const ChangePassword = React.lazy(() => import('@/pages/acc/ChangePassword'))

// Component
const CompBanner = React.lazy(() => import('@/components/CompBanner'))
const CompFooter = React.lazy(() => import('@/components/CompFooter'))
const CompHeader = React.lazy(() => import('@/components/CompHeader'))
const CompPath = React.lazy(() => import('@/components/CompPath'))
const CompSideMenu = React.lazy(() => import('@/components/CompSideMenu'))

// Introduce
const WhyMSC = React.lazy(() => import('@/pages/introduce/WhyMSC'))
const Greetings = React.lazy(() => import('@/pages/introduce/Greetings'))
const Branch = React.lazy(() => import('@/pages/introduce/Branches'))
const Map = React.lazy(() => import('@/pages/introduce/Maps'))
const Recruit = React.lazy(() => import('@/pages/introduce/Recruit'))

// Program
const Overview = React.lazy(() => import('@/pages/program/Overview'))
const Elementary = React.lazy(() => import('@/pages/program/Elementary'))
const High = React.lazy(() => import('@/pages/program/High'))
const SmartReading = React.lazy(() => import('@/pages/program/SmartReading'))
const Science = React.lazy(() => import('@/pages/program/Science'))
const SpecialDesc = React.lazy(() => import('@/pages/program/SpecialDesc'))

// VOD/
const Seminar = React.lazy(() => import('@/pages/vod/Seminar'))
const Peek = React.lazy(() => import('@/pages/vod/Peek'))

// Press
const News = React.lazy(() => import('@/pages/press/News'))
const Columns = React.lazy(() => import('@/pages/press/Columns'))
const Promotion = React.lazy(() => import('@/pages/press/Promotion'))

// Enroll
const Guide = React.lazy(() => import('@/pages/enroll/Guide'))
const Presentation = React.lazy(() => import('@/pages/enroll/Presentation'))
const EntranceTest = React.lazy(() => import('@/pages/enroll/EntranceTest'))
const EntranceTestPay = React.lazy(() => import('@/pages/enroll/pay/EntranceTestPay'))
const Counseling = React.lazy(() => import('@/pages/enroll/Counseling'))

// Zone
const Notice = React.lazy(() => import('@/pages/zone/Notice'))
const Learning = React.lazy(() => import('@/pages/zone/Learning'))
const BestReport = React.lazy(() => import('@/pages/zone/BestReport'))
const ExperienceCase = React.lazy(() => import('@/pages/zone/ExperienceCase'))
const FAQ = React.lazy(() => import('@/pages/zone/FAQ'))

// User
const Account = React.lazy(() => import('@/pages/user/Account'))
const Applications = React.lazy(() => import('@/pages/user/Applications'))

// Board
const BoardDetail = React.lazy(() => import('@/pages/board/BoardDetail'))

// Parent Test + Student Retest
const TestMain = React.lazy(() => import('@/pages/enquiry/TestMain'))
const RetestMain = React.lazy(() => import('@/pages/enquiry/RetestMain'))
const TestTerms = React.lazy(() => import('@/pages/enquiry/TestTerms'))
const Testing = React.lazy(() => import('@/pages/enquiry/Testing'))
const Retesting = React.lazy(() => import('@/pages/enquiry/Retesting'))
const TestDone = React.lazy(() => import('@/pages/enquiry/TestDone'))

const DaumPostcode = React.lazy(() => import('@/pages/DaumPostcode'))

const CompLoading = React.lazy(() => import('@/components/CompLoading'))
const CompModal = React.lazy(() => import('@/components/CompModal'))

type layoutType = {
  sideNav: boolean
  children?: React.ReactNode
}

Kakao.init('4b0cad0f46944031046cc53746ef5bc0')

/**
 * @component
 * * 테스트.
 */
function Enquiry() {
  return (
    <div className={rootStyle.Root}>
      <Outlet />
    </div>
  )
}

/**
 * @component
 * * 인증 확인.
 */
function RequireAuth({ children }: { children: JSX.Element }) {
  const { isLoggedIn } = useContext(iMSCContext)

  return isLoggedIn ? children : <Signin />
}

/**
 * @component
 * * MSC 기본 레이아웃.
 */
function FullscreenLayout(props: layoutType): React.ReactElement {
  const [isShowFullscreen, setIsShowFullscreen] = useState(false)

  const location = useLocation()

  useEffect(() => {
    const path = location.pathname

    setIsShowFullscreen(path === '/')

    // 비밀번호 초기화 제외.
    if (!path.startsWith('/change/password')) {
      // 게시판 상세보기 log 간략하게.
      if (path.startsWith('/board')) {
        const itemIdIdx = path.indexOf('/', 1)
        const itemId = path.substring(itemIdIdx + 1)
        logEvent('/board', { itemId })
      } else {
        logEvent(path)
      }
    }
  }, [location])

  return (
    <>
      {!isShowFullscreen && <CompBanner />}
      {!isShowFullscreen && props.sideNav && <CompPath />}

      <div id="contents" style={isShowFullscreen ? { backgroundColor: '#F4F4F5' } : {}}>
        <div className="wrapper">
          <div className="container">
            {!isShowFullscreen && props.sideNav && <CompSideMenu />}

            <div className="contents-area">{props.children}</div>
          </div>
        </div>
      </div>
    </>
  )
}

/**
 * @components
 * * App.
 */
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isShowSideMenu, setIsShowSideMenu] = useState(false)
  const [mainCategory, setMainCategory] = useState('default')
  const [subCategory, setSubCategory] = useState('why_msc')
  const [sideMenuIdx, setSideMenuIdx] = useState(0)
  const [isAuth, setIsAuth] = useState(false)

  const [isShowLogoutModal, setIsShowLogoutModal] = useState(false)

  const navigate = useNavigate()

  const ctxValue = {
    isLoggedIn,
    isShowSideMenu,
    mainCategory,
    subCategory,
    sideMenuIdx,
    isAuth,
    setIsLoggedIn: (value: boolean) => setIsLoggedIn(value),
    setIsShowSideMenu: (value: boolean) => setIsShowSideMenu(value),
    setMainCategory: (value: string) => setMainCategory(value),
    setSubCategory: (value: string) => setSubCategory(value),
    setSideMenuIdx: (value: number) => setSideMenuIdx(value),
    setIsAuth: (value: boolean) => setIsAuth(value),
  }

  /**
   * 사용자 아이콘 클릭.
   */
  function onUserClick() {
    if (!isLoggedIn) navigate('/signin')
  }

  /**
   * CompHeader 로그아웃 선택.
   */
  function onLogoutClick() {
    setIsShowLogoutModal(true)
  }

  /**
   * 사용자 로그아웃 실행.
   */
  function performLogout() {
    AuthService.logout<ReturnDTO>()
      .then(({ resultCode, resultMsg }) => {
        if (resultCode === 0) {
          setIsShowLogoutModal(false)

          delete localStorage['userSeqId']
          delete localStorage['memberId']
          delete localStorage['enrollType']

          setAuthorizationToken()
          setIsLoggedIn(false)

          navigate('/', { replace: true })
        } else {
          alert(resultMsg)
        }
      })
      .catch((error) => {
        alert(error.resultMsg ?? error)
      })
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      init(import.meta.env.VITE_AMPLITUDE_KEY, { defaultTracking: true })
    }

    const userSeqId = localStorage.getItem('userSeqId')
    const memberId = localStorage.getItem('memberId')
    const enrollType = localStorage.getItem('enrollType')

    if (!userSeqId && !memberId && !enrollType) {
      return
    }

    if (userSeqId && memberId && enrollType) {
      AuthService.silentRefresh<ReturnDTO>()
        .then(({ resultCode, resultData }) => {
          if (resultCode === 0) {
            const newUserSeqId = resultData?.userSeqId
            const newMemberId = resultData?.memberId
            const newEnrollType = resultData?.enrollType

            if (
              newUserSeqId == userSeqId &&
              newMemberId == memberId &&
              newEnrollType == enrollType
            ) {
              const accessToken = resultData?.accessToken
              setAuthorizationToken(accessToken)
              setIsLoggedIn(!isEmpty(accessToken))
            } else {
              throw new Error('사용자 인증 식별 코드가 일치하지 않아 로그아웃 됩니다.')
            }
          } else {
            throw new Error('사용자 인증에 실패하여 로그아웃 됩니다.')
          }
        })
        .catch((e) => {
          if (e.message) {
            alert(e.message)
          } else if (e.resultMsg) {
            alert(e.resultMsg)
          }
          navigate('/quit', { replace: true })
        })
    } else {
      navigate('/quit', { replace: true })
    }
  }, [])

  return (
    <iMSCContext.Provider value={ctxValue}>
      <Suspense fallback={<CompLoading />}>
        {location.pathname === '/address' ? (
          <Routes>
            <Route path="/address" element={<DaumPostcode />} />
          </Routes>
        ) : location.pathname.startsWith('/test') ||
          location.pathname.startsWith('/retest') ? (
          <Routes>
            {/* Parenting test */}
            <Route path="/test" element={<Enquiry />}>
              <Route index element={<TestMain />} />
              <Route path="terms" element={<TestTerms />} />
              <Route path="testing" element={<Testing />} />
              <Route path="done" element={<TestDone />} />
            </Route>
            {/* Student Retest */}
            <Route path="/retest" element={<Enquiry />}>
              <Route index element={<RetestMain />} />
              <Route path="testing" element={<Retesting />} />
            </Route>
          </Routes>
        ) : location.pathname === '/counseling' ? (
          <Routes>
            <Route path="/counseling" element={<Counseling />} />
          </Routes>
        ) : (
          <>
            <CompHeader onAccClick={onUserClick} onLogoutClick={onLogoutClick} />

            <FullscreenLayout sideNav={isShowSideMenu}>
              <Routes>
                {/* Common */}
                <Route path="/" element={<Main />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/quit" element={<Quit />} />
                {/* Account */}
                <Route path="/signin/*" element={<Signin />} />
                <Route path="/findAccount" element={<FindAccount />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/change/password/" element={<ChangePassword />}>
                  <Route path=":urlCode" element={<ChangePassword />} />
                </Route>
                {/* Introduce */}
                <Route path="/whymsc" element={<WhyMSC />} />
                <Route path="/greetings" element={<Greetings />} />
                <Route path="/branches" element={<Branch />} />
                <Route path="/maps" element={<Map />} />
                <Route path="/recruit" element={<Recruit />} />
                {/* Program */}
                <Route path="/overview" element={<Overview />} />
                <Route path="/elementary" element={<Elementary />} />
                <Route path="/high" element={<High />} />
                <Route path="/smartReading" element={<SmartReading />} />
                <Route path="/science" element={<Science />} />
                <Route path="/specialDesc" element={<SpecialDesc />} />
                {/* VOD */}
                <Route path="/seminar" element={<Seminar />} />
                <Route path="/peek" element={<Peek />} />
                {/* Press */}
                <Route path="/news" element={<News />} />
                <Route path="/columns" element={<Columns />} />
                <Route path="/promotion" element={<Promotion />} />
                {/* Enroll */}
                <Route path="/guide" element={<Guide />} />
                <Route path="/presentation" element={<Presentation />} />
                <Route
                  path="/entranceTest"
                  element={
                    <RequireAuth>
                      <EntranceTest />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/entranceTestPay"
                  element={
                    <RequireAuth>
                      <EntranceTestPay />
                    </RequireAuth>
                  }
                />
                {/* Zone */}
                <Route path="/notice" element={<Notice />} />
                <Route path="/learning" element={<Learning />} />
                <Route path="/bestReport" element={<BestReport />} />
                <Route path="/experienceCase" element={<ExperienceCase />} />
                <Route path="/faq" element={<FAQ />} />
                {/* User */}
                <Route
                  path="/account"
                  element={
                    <RequireAuth>
                      <Account />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/applications"
                  element={
                    <RequireAuth>
                      <Applications />
                    </RequireAuth>
                  }
                />
                {/* Board */}
                <Route path="/board/:id" element={<BoardDetail />} />
              </Routes>
            </FullscreenLayout>

            <CompFooter />

            {isShowLogoutModal && (
              <CompModal
                modalTitle="로그아웃"
                onCloseClick={() => setIsShowLogoutModal(false)}
                onConfirmClick={performLogout}
              >
                <span>로그아웃 하시겠습니까?</span>
              </CompModal>
            )}
          </>
        )}
      </Suspense>
    </iMSCContext.Provider>
  )
}

export default App
